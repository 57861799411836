<template>
    <div class="s-page">
        <div class="s-header">
            <van-nav-bar
                :title="$t('finance.title')"
                left-arrow
                @click-left="$router.go(-1)"
            />
        </div>

        <div class="s-content">
            <div class="s-divider"></div>
            <div class="s-card balance">
                <van-row class="data">
                    <van-col span="12">
                        <div class="title">{{ $t('finance.balance') }} <span>{{ balanceInfo.rate }}</span></div>
                        <div class="price"><span>{{ balanceInfo.total }}</span> USTD</div>
                        <!--<div>≈$100.00</div>-->
                    </van-col>
                    <van-col span="12">
                        <div>{{ $t('finance.totalRevenue') }}</div>
                        <div class="income" :class="balanceInfo.total_profit > 0 ? 'increase' : 'reduce'">{{ balanceInfo.total_profit }}</div>
                        <div>{{ $t('finance.yesterdayEarnings') }}</div>
                        <div class="income" :class="balanceInfo.yesterday_profit > 0 ? 'increase' : 'reduce'">{{ balanceInfo.yesterday_profit }}</div>
                    </van-col>
                </van-row>
                <van-row gutter="24" class="operating">
                    <van-col span="12">
                        <van-button plain block @click="transfer('out')">{{ $t('finance.out') }}</van-button>
                    </van-col>
                    <van-col span="12">
                        <van-button type="info" block @click="transfer('in')">{{ $t('finance.in') }}</van-button>
                    </van-col>
                </van-row>
            </div>

            <!--固定收益明细-->
            <div class="incomeDetails">
                <h2>{{ $t('finance.incomeDetails') }}</h2>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="$t('common.nomore')"
                    :loading-text="$t('common.loading')"
                    @load="fetchIncomeDetails"
                >
                    <van-cell
                        v-for="item in incomeList"
                        :key="item.id"
                        :title="$t('finance.income')"
                        :label="item.createtime"
                        :value="item.money"
                        :valueClass="item.money > 0 ? 'increase' :
'reduce'"
                    />
                </van-list>
            </div>
        </div>

        <!--转出-->
        <van-popup
            v-model="transferPopup"
            round
            :style="{ width: '80%' }"
        >
            <div class="transferBlock">
                <div class="title">{{ transferText[transferType] }}</div>
                <van-form @submit="handleTransfer" ref="transferForm" label-width="5em">
                    <van-field
                        v-model="transferForm.amount"
                        number="number"
                        label-class="labelClass"
                        name="amount"
                        :label="$t('finance.amount')"
                        :placeholder="$t('finance.transferAmount')"
                        :rules="[{ required: true, message: '' }]"
                    />
                    <div class="tips">{{ transferTip[transferType] }}{{transferType === 'out' ? balanceInfo.total : balanceInfo.balance}}usdt</div>
                    <div class="van-hairline--top van-dialog__footer">
                        <van-button size="large" native-type="button" class="van-dialog__cancel" @click="closeTransfer">
                            {{ $t('common.cancel') }}</van-button>
                        <van-button size="large" native-type="submit" class="van-dialog__confirm">{{ $t('common.confirm') }}</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
    </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
    name: 'finance',
    data() {
        return {
            dayjs,
            balanceInfo: {
                total: 0,
                total_profit: 0,
                yesterday_profit: 0,
                balance: 0
            },

            // 转账
            transferPopup: false,
            transferType: 'out', // int: 转入， out: 转出
            transferText: {
                out: this.$t('finance.out'),
                in: this.$t('finance.in')
            },
            transferTip: {
                out: this.$t('finance.outTip'),
                in: this.$t('finance.inTip')
            },
            transferForm: {
                amount: '',
            },

            loading: false,
            finished: false,
            incomeList: [],
            page: 1,
            pageSize: 10,
            pageTotal: 0,
        }
    },
    mounted() {
        this.fetchBalanceInfo()
    },
    methods: {
        // 获取余额信息
        fetchBalanceInfo() {
            this.$http.get('/home/yuebao/info').then(res => {
                this.balanceInfo = res.data.data
            })
        },
        // 获取固定收益明细
        fetchIncomeDetails() {
            this.loading = true
            this.$http.get('/home/yuebao/log', {
                params: {
                    type: 'income',
                    page: this.page,
                    pagesize: this.pageSize
                }
            }).then(res => {
                let data = res.data.data
                this.incomeList = this.incomeList.concat(data.data);
                this.pageTotal = data.count / this.pageSize
                this.loading = false
                if (this.page >= this.pageTotal) {
                    this.finished = true;
                }
                this.page++
            }).catch(err => {
                this.loading = false
                this.finished = true
            })
        },

        // 转账
        transfer(type) {
            this.transferType = type
            this.transferPopup = true
        },
        // 提交转账
        handleTransfer(values) {
            this.$http.post('/home/yuebao/transfer',{
                type: this.transferType,
                money: values.amount
            }).then(res => {
                // console.log(res.data)
                this.$toast(this.$t(res.data.msg))
                if (res.data.code === 200) {
                    this.closeTransfer()
                    this.fetchBalanceInfo()
                }
            })
        },
        // 关闭弹窗
        closeTransfer() {
            this.$refs.transferForm.resetValidation()
            this.transferForm.amount = ''
            this.transferPopup = false
        },
    }
}
</script>

<style scoped lang="less">
.s-page{
    background-color: #f7f7f7;
}
.balance{
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    padding: 20px;
    .data{
        .title{
            font-size: 15px;
            span{
                color: #AB4650;
            }
        }
        .price{
            font-weight: bold;
            color: #000000;
            margin-top: 10px;
            span{
                font-size: 30px;
            }
        }
        .income{
            font-size: 12px;
            line-height: 24px;
            &.increase{
                color: #85B480;
            }
            &.reduce{
                color: #AB4650;
            }
        }
    }
    .operating{
        margin-top: 20px;
        .van-button{
            border-radius: 5px;
            border: 1px solid #999999;
            color: #999999;
            font-size: 15px;
            &.van-button--info{
                background: #0055fe;
                border-color: transparent;
                color: #FFFFFF;
            }
        }
    }
}
.incomeDetails{
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    text-align: left;
    h2{
        font-weight: 500;
        color: #000000;
        padding: 18px;
    }
    .increase{
        color: #85B480
    }
    .reduce{
        color: #AB4650
    }
}
.van-popup--round{
    border-radius: 5px;
}
.transferBlock{
    color: #0055fe;
    text-align: left;
    padding: 0 10px;
    .title{
        padding: 15px 0;
        border-bottom: 1px solid #0055fe;
        margin-bottom: 15px;
        text-align: center;
    }
    ::v-deep {
        .labelClass{
            color: #0055fe;
        }
    }
    .tips{
        font-size: 11px;
        padding-left: 16px;
        margin-bottom: 30px;
    }
    [class*=van-hairline]::after{
        border-color: #0055fe;
    }
    .van-dialog__cancel{
        border-right: 1px solid rgba(0, 78, 163, .2);
        color: #999999;
        font-weight: bold;
    }
    .van-dialog__confirm{
        color: #0055fe;
        font-weight: bold;
    }
}
</style>
